var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0605fde89da98aed5187445522a72020153f8aeb"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

// import * as Sentry from '@sentry/browser';

import * as Sentry from '@sentry/nextjs';
// import { ProfilingIntegration } from '@sentry/profiling-node';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENVIRONMENT =
  process.env.NEXT_PUBLIC_SENTRY_ENV ||
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ||
  'development';

Sentry.init({
  dsn: SENTRY_DSN || '',
  debug: false,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_TRACING || 0.5,
  environment: SENTRY_ENVIRONMENT,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: SENTRY_ENVIRONMENT === 'development' ? 0 : 0.2,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  profilesSampleRate: process.env.NEXT_PUBLIC_SENTRY_PROFILING || 0.5,

  integrations: [
    new Sentry.Replay(),
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.BrowserTracing(),
  ],
});
